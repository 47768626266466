import * as React from 'react';
import { filter, concat, set, includes, pickBy } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons, DataTableNameValue, DataTable, Content, IconTitle, graphql, entityConnectionTypeSetsDefault, entityCoreFormFields } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { themes } from '@shapeable/theme';

const theme = themes.LAB_THEME;
const appSectionConfigs = coreAppSectionConfigs;
const { platform } = appSectionConfigs;
const entityConnectionTypeSets = entityConnectionTypeSetsDefault;
export const application = createApplication({
  theme,
  entityConfigs: {
    // ...entityConfigs,
    ...pickBy(entityConfigs, config => !includes([
      "ImpactStory", "Benefit", "BenefitType", "Cluster",
      "OfferingType", "MaturityStage", 
      "StrategicPriority", "ProjectType", "ProviderType",  
    ], config.name)),
  },
  appSectionConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
    entityConnectionTypeSets
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
